import React, { useState, useContext } from "react";
import ListLoading from "./ListLoading";
import { connect } from "react-redux";
import {Card, fade, Grid, InputBase, makeStyles, Typography} from "@material-ui/core"
import { ActionContext } from "../../../../contexts";
import {HeaderButton, Title} from "../../../shared";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

let useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.colors.veryLightGrey,
    display: "flex",
    height: `calc(100vh - 50px)`,
    zIndex: theme.zIndex.appBar,
  },
  container: {
    padding: 16,
    marginRight: "auto",
  },
  fullName: {
    color: theme.colors.primaryNavy,
    fontFamily: theme.fontFamily.heading,
  },
  team: {
    color: theme.palette.text.disabled,
    fontSize: '12px',
    fontFamily: theme.fontFamily.robotoCondensed,
  },
  card: {
    flexGrow: 0,
    padding: '14px',
    borderRadius: '9px',
    boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.06)',
    backgroundColor: theme.palette.common.white,
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  search: {
    justifyContent: 'space-between',
    display: 'flex',
    position: 'relative',
    marginBottom: '14px',
    marginRight: '0px',
    marginTop: '14px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    marginLeft: 0,
    width: '100%',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    padding: '1rem 0px',
  },
  title: {
    fontSize: theme.fontSize.md,
    fontFamily: theme.fontFamily.heading,
    color: theme.colors.primaryNavy,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '8px 15px',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    paddingLeft: '10px',
    maxWidth: 330 ?? '100%',
    borderRadius: 5,
    color: theme.colors.darkBlue60Percent,
    backgroundColor: theme.colors.veryLightGrey,
    fontFamily: theme.fontFamily.robotoCondensed,
    marginRight: '1rem',
    fontWeight: 500,
    border: 'none',
  },
  iconColor: {
    color: theme.palette.text.primary,
    cursor: 'pointer',
  },
}));

let ConferencesList = (props) => {
  let classes = useStyles();
  let { conferenceData, loading, setOpen } = props;
  let { conferenceActions, sportsActions } = useContext(ActionContext);
  const [searchText, setSearchText] = useState(undefined);

  function handleSearchText(v) {
    setSearchText(v);
  }

  function handleCardClick(conferenceId, name, sportName) {
    conferenceActions.getTeamsByConferenceId(conferenceId);
    conferenceActions.selectConference(conferenceId);
    conferenceActions.selectConferencePage("team")
    conferenceActions.selectConferenceName(name);
    conferenceActions.selectedConferenceSportName(sportName);
  }

  const filteredData = searchText ? conferenceData && conferenceData.filter(v => v.name.toLowerCase().includes(searchText.toLowerCase())) : conferenceData && conferenceData
  const sortedFilterData = filteredData && filteredData.sort((a, b) => a.name.localeCompare(b.name));

  const handleCreate = () => {
    sportsActions.selectedFormState('create');
    conferenceActions.deselectConference();
    setOpen(true);
  };

  const handleUpdate = (id) => {
    sportsActions.selectedFormState('update');
    conferenceActions.getConferenceById(id);
    setOpen(true);
  };

  return (
      <>
        <div className={classes.search}>
          <Title title='Conference' />
          <div style={{display: 'flex'}}>
            <HeaderButton onclick={handleCreate} title='Create Conference'/>
            <InputBase
                placeholder="Search Conference Name"
                value={searchText}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{'aria-label': 'search'}}
                onChange={ev => handleSearchText(ev.target.value)}
            />
          </div>
        </div>
        <Grid container spacing={1}>
          {
            loading ?
                <ListLoading/>
                :
                sortedFilterData && sortedFilterData.length === 0 ?
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px', color: '#e61e21'}}>
                      {"No conference found"}
                    </div>
                    :
                    sortedFilterData && sortedFilterData.map(list => (
                        <Grid item xs={12} sm={4}>
                          <Card className={classes.card} >
                            <div style={{display: 'flex'}}>
                              <div style={{width: '100%', marginLeft: '1rem'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                  <div onClick={() => handleCardClick(list._id, list.name, list.sport_name)}>
                                    <Typography className={classes.fullName}>{list.name}</Typography>
                                  </div>
                                  <div onClick={() => handleUpdate(list._id)}>
                                    <CreateOutlinedIcon className={classes.iconColor}/>
                                  </div>
                                </div>
                                <div className={classes.team}>
                                  {list.procode}
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Grid>
                    ))
          }
        </Grid>
      </>
  );
};

let mapStateToProps = (state) => {
  return {
    loading: state.ui.conference.loadingStats,
    conferenceData: state.conference.allConferenceList,
  };
};

export default connect(mapStateToProps)(ConferencesList);

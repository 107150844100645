import * as types from "./sportsType";
import { sportsApi } from "../../../api";

import {
    showSuccessMessage,
    showErrorMessage,
} from "../../shared/Snackbar";

const getSportsList = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.GET_ALL_SPORTS_REQUEST,
                payload: []
            });

            let response = await sportsApi.getAllSports();

            dispatch({
                type: types.GET_ALL_SPORTS_SUCCESS,
                payload: response
            });

        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_ALL_SPORTS_FAILED,
                payload: "Failed to fetch sports",
            });
        }
    };
};

const selectSportById = (sportId, successCb = (f) => f) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.GET_SPORT_BY_ID_REQUEST,
            });

            const sport = await sportsApi.getSportById(sportId);
            dispatch({
                type: types.GET_SPORT_BY_ID_SUCCESS,
                payload: sport,
            });

            dispatch(showSuccessMessage(`Successfully got ${sport.name}`));
            successCb();
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_SPORT_BY_ID_FAILED,
                payload: "Failed to fetch sport by id",
            });
        }
    };
};

const searchSport = (key) => {
    return {
        type: types.SEARCH_SPORT,
        payload: {
            key,
        },
    };
};

const resetSearch = () => {
    return {
        type: types.RESET_SEARCH,
    };
};

const selectSport = (sportId) => {
    return {
        type: types.SELECT_SPORT,
        payload: {
            sportId,
        },
    };
};

const deselectSport = () => {
    return {
        type: types.DESELECT_SPORT,
        payload: {},
    };
};

const selectedSportName = (sportName) => {
    return {
        type: types.SELECTED_SPORT_NAME,
        payload: {
            sportName,
        },
    };
};

const resetUnitsApi = () => {
    return {
        type: types.RESET_SPORT_UNIT_BY_SPORT_ID,
        payload: []
    }
}

const getSportPositionDetails = (sportId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.GET_SPORT_POSITION_BY_UNIT_ID_REQUEST,
                payload: [],
            });

            let response = await sportsApi.getPositionDetails(sportId);

            dispatch({
                type: types.GET_SPORT_POSITION_BY_UNIT_ID_SUCCESS,
                payload: response
            });
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_SPORT_POSITION_BY_UNIT_ID_FAILED,
                payload: {
                    error: "Fetch sport POSITION failed",
                },
            });
        }
    };
};

const getSportPositionById = (id, successCb = (f) => f) => {
    return async (dispatch) => {
       try {
           dispatch({
               type: types.GET_SPORT_POSITION_BY_ID_REQUEST,
           });

           const position = await sportsApi.getPositionById(id);
           dispatch({
               type: types.GET_SPORT_POSITION_BY_ID_SUCCESS,
               payload: position,
           });

           dispatch(showSuccessMessage(`Successfully got ${position.position}`));
           successCb();
       } catch (err) {
           dispatch(showErrorMessage(err.message));
           dispatch({
               type: types.GET_SPORT_POSITION_BY_ID_FAILED,
               payload: 'Failed to get the position',
           })
       }
    };
};

const deselectPosition = () => {
    return async (dispatch) => {
       dispatch({
           type: types.DESELECT_SPORT_POSITION,
           payload: {},
       });
    };
};

const resetSportsApi = () => {
    return {
        type: types.RESET_SPORT_POSITION_BY_UNIT_ID,
        payload: []
    }
}

const selectSportsPage = (page) => {
    return {
        type: types.SELECTED_PAGE_TYPE,
        payload: page
    };
};

const selectSportsType = (type) => {
    return {
        type: types.SELECTED_SPORTS_TYPE,
        payload: type
    };
};

const getTeamUsersList = (team) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.GET_TEAM_USERS_REQUEST,
                payload: []
            });

            let response = await sportsApi.getTeamUsers(team);

            dispatch({
                type: types.GET_TEAM_USERS_SUCCESS,
                payload: response
            });

        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_TEAM_USERS_FAILED,
                payload: "Failed to fetch Team Users",
            });
        }
    };
};

const resetSportCreationError = () => {
    return {
        type: types.RESET_SPORT_CREATION_ERROR,
    }
};

const createSport = (name, successCb = (f) => f) => {
    return async (dispatch) => {
      try {
          dispatch({
              type: types.CREATE_SPORT_REQUEST,
          });

          let data = await sportsApi.createSport(name);
          dispatch({
              type: types.CREATE_SPORT_SUCCESS,
              payload: data,
          });

          dispatch(getSportsList());
          dispatch(showSuccessMessage('Sport created successfully'));
          successCb();
      } catch (err) {
          dispatch(showErrorMessage(err.message));
          dispatch({
              type: types.CREATE_SPORT_FAILED,
              payload: 'Sport creation failed',
          });
      }
    };
};

const updateSport = (sportId, name, successCb = (f) => f) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.UPDATE_SPORT_REQUEST,
            });

            await sportsApi.updateSport(sportId, name);
            dispatch({
                type: types.UPDATE_SPORT_SUCCESS,
            });

            dispatch(getSportsList());
            dispatch(showSuccessMessage('Successfully updated sport'));
            successCb();
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.UPDATE_SPORT_FAILED,
                payload: 'Failed to update sport',
            });
        }
    };
};

const getAllUnits = (successCb = (f) => f) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.GET_ALL_SPORTS_UNITS_REQUEST,
            });

            const units = await sportsApi.getAllSportsUnits();
            dispatch({
                type: types.GET_ALL_SPORTS_UNITS_SUCCESS,
                payload: units,
            })

            successCb();
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_ALL_SPORTS_UNITS_FAILED,
                payload: 'Failed to get units',
            })
        }
    };
};

const getSportUnitDetails = (sportId) => {

    return async (dispatch) => {
        try {
            dispatch({
                type: types.GET_SPORT_UNIT_BY_SPORT_ID_REQUEST,
                payload: [],
            });

            let response = await sportsApi.getUnitsBySportId(sportId);

            dispatch({
                type: types.GET_SPORT_UNIT_BY_SPORT_ID_SUCCESS,
                payload: response
            });
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_SPORT_UNIT_BY_SPORT_ID_FAILED,
                payload: {
                    error: "Fetch sport unit failed",
                },
            });
        }
    };
};

const getUnitById = (unitId, successCb = (f) => f) => {
    return async (dispatch) => {
      try {
         dispatch({
             type: types.GET_UNIT_BY_ID_REQUEST,
         });

         const unit = await sportsApi.getUnitById(unitId);
         dispatch({
             type: types.GET_UNIT_BY_ID_SUCCESS,
             payload: unit,
         });

         dispatch(showSuccessMessage('Successfully got unit'));
         successCb();
      } catch (err) {
          dispatch(showErrorMessage(err.message));
          dispatch({
              type: types.GET_UNIT_BY_ID_FAILED,
              payload: 'Failed to get unit',
          });
      }
    };
};

const selectSportUnit = (unitId) => {
    return {
        type: types.SELECT_SPORT_UNIT,
        payload: {
            unitId,
        },
    };
};

const deselectSportUnit = () => {
    return {
        type: types.DESELECT_SPORT_UNIT,
        payload: {},
    };
};

const resetUnitCreationError = () => {
    return {
        type: types.RESET_UNIT_CREATION_ERROR,
    }
};

const createUnit = (data, sportId, successCb =(f) => f) => {
    return async (dispatch) => {
      try {
          dispatch({
              type: types.CREATE_UNIT_REQUEST,
          });

          const unit = await sportsApi.createUnit(data);
          dispatch({
              type: types.CREATE_UNIT_SUCCESS,
              payload: unit,
          });

          dispatch(getSportPositionDetails(sportId));
          dispatch(showSuccessMessage('Successfully created unit'));
          successCb();
      } catch (err) {
          dispatch(showErrorMessage(err.message));
          dispatch({
              type: types.CREATE_UNIT_FAILED,
              payload: 'Failed to create unit',
          })
      }
    };
};

const updateUnit = (unitId, data, sportId, successCb = (f) => f) => {
    return async (dispatch) =>{
        try {
            dispatch({
                type: types.UPDATE_UNIT_BY_ID_REQUEST,
            });

            const unit = await sportsApi.updateUnit(unitId, data);
            dispatch({
                type: types.UPDATE_UNIT_BY_ID_SUCCESS,
                payload: unit,
            });

            dispatch(getSportUnitDetails(sportId));
            dispatch(showSuccessMessage('Successfully updated unit'));
            successCb();
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.UPDATE_UNIT_BY_ID_FAILED,
                payload: 'Update failed',
            });
        }
    };
};

const resetSportPositionCreationError = () => {
    return {
        type: types.RESET_SPORT_POSITION_CREATION_ERROR,
    }
};

const createPosition = (data, sportId, successCb = (f) => f) => {
  return async (dispatch) => {
      try {
          dispatch({
              type: types.CREATE_POSITION_REQUEST,
          });

          const position = await sportsApi.createPosition(data);
          dispatch({
              type: types.CREATE_POSITION_SUCCESS,
              payload: position,
          });

          dispatch(getSportPositionDetails(sportId));
          dispatch(showSuccessMessage('Successfully created position'));
          successCb();
      } catch (err) {
          dispatch(showErrorMessage(err.message));
          dispatch({
              type: types.CREATE_POSITION_FAILED,
              payload: 'Failed to create new position',
          });
      }
  };
};

const updateSportsPosition = (id, data, sportId, successCb = (f) => f) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.UPDATE_POSITION_REQUEST,
            });

            const position = await sportsApi.updatePosition(id, data, sportId);
            dispatch({
                type: types.UPDATE_POSITION_SUCCESS,
                payload: position,
            });

            dispatch(getSportPositionDetails(sportId));
            dispatch(showSuccessMessage('Successfully updated position'))
            successCb();
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.UPDATE_POSITION_FAILED,
                payload: "Failed to update position",
            })
        }
    };
};

const deleteSportsPosition = (id, successCb = (f) => f) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.DELETE_POSITION_REQUEST,
            });

            const position = await sportsApi.deletePosition(id);
            dispatch({
                type: types.DELETE_POSITION_SUCCESS,
                payload: position,
            });

            dispatch(showSuccessMessage('Successfully deleted position'));
            successCb();
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.DELETE_POSITION_FAILED,
                payload: "Failed to delete position",
            })
        }
    };
};

const selectedFormName = (name) => {
    return {
        type: types.FORM_NAME_SELECTED,
        payload: {
            name,
        },
    };
};

const deselectFormName = () => {
    return {
        type: types.FORM_NAME_DESELECTED,
        payload: {},
    };
};

const selectedFormState = (name) => {
    return {
        type: types.FORM_STATE_SELECTED,
        payload: {
            name,
        },
    };
};

const deselectFormState = () => {
    return {
        type: types.FORM_STATE_DESELECTED,
        payload: {},
    };
};

const sportsActions = {
    getSportsList,
    selectSportById,
    searchSport,
    resetSearch,
    selectSport,
    deselectSport,
    selectedSportName,
    getSportPositionDetails,
    getSportPositionById,
    selectSportsPage,
    selectSportsType,
    getTeamUsersList,
    resetUnitsApi,
    resetSportsApi,
    resetSportCreationError,
    createSport,
    updateSport,
    resetUnitCreationError,
    getUnitById,
    getSportUnitDetails,
    selectSportUnit,
    createUnit,
    updateUnit,
    deselectSportUnit,
    getAllUnits,
    resetSportPositionCreationError,
    createPosition,
    updateSportsPosition,
    deleteSportsPosition,
    selectedFormName,
    deselectFormName,
    selectedFormState,
    deselectFormState,
    deselectPosition,
};

export default sportsActions;
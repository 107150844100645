import React, { useContext } from "react";
import CreateTeamDialog from "./TeamDialogForm"
import { ActionContext } from "../../../../contexts";
import {HeaderButton} from "../../../shared";

const CreateTeam = (props) => {
  const { conferenceName } = props;
  const { dialogActions, sportsActions } = useContext(ActionContext);

  const handleClick = () => {
    sportsActions.selectedFormState('create');

    dialogActions.openDialog(
      <CreateTeamDialog conferenceName={conferenceName} />,
      {
        maxWidth: "xs",
      }
    );
  };

  return <HeaderButton title='Create Team' onclick={handleClick}/>;
};

export default CreateTeam;

import React, {useState, useContext} from 'react'
import {Card, InputBase, Grid, makeStyles, fade, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import {ActionContext} from "../../../../contexts";
import ListLoading from "../ConferenceList/ListLoading";
import CreateTeam from "./CreateTeam"
import TeamDialogForm from "./TeamDialogForm"
import "./style.css"
import {BackArrow, Title} from "../../../shared";

let useStyles = makeStyles((theme) => ({
    container: {
        padding: 16,
        marginRight: "auto",
    },
    fullName: {
        color: theme.colors.primaryNavy,
        fontFamily: theme.fontFamily.heading,
    },
    card: {
        flexGrow: 0,
        padding: '14px',
        borderRadius: '9px',
        maxHeight: 50,
        boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.06)',
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            cursor: 'pointer'
        },
    },
    search: {
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
        marginRight: '0px',
        backgroundColor: fade(theme.palette.common.white, 0.15),
        marginLeft: 0,
        width: '100%',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        paddingBottom: '10px',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: '8px 15px',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        paddingLeft: '10px',
        maxWidth: 330 ?? '100%',
        borderRadius: 5,
        color: theme.colors.darkBlue60Percent,
        backgroundColor: theme.colors.veryLightGrey,
        fontFamily: theme.fontFamily.robotoCondensed,
        fontWeight: 500,
        border: 'none',
    },
    editIcon: {
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        color: theme.palette.primary.main
    },
    btnPrimary: {
        fontSize: '13px',
        fontFamily: theme.fontFamily.robotoCondensed,
        color: theme.palette.text.primary,
        fontWeight: 700,
        cursor: 'pointer',
        textTransform: 'uppercase',
        border: 'none',
        marginRight: 20,
        display: "flex",
        alignItems: "center",
    }
}));

function ConferenceDetails(props) {
    const { teamData, loading, conferenceName } = props;
    let classes = useStyles();
    let actions = useContext(ActionContext);
    let { conferenceActions, dialogActions, sportsActions } = actions;
    const [searchText, setSearchText] = useState(undefined);

    function handleSearchText(v) {
        setSearchText(v);
    }

    const handleRedirect = () => {
        conferenceActions.selectConferencePage("list");
        conferenceActions.selectConferenceName(null);
        conferenceActions.resetTeamByConferenceId();
    };

    function handleEditClick(data) {
        sportsActions.selectedFormState('update');

        dialogActions.openDialog(
            <TeamDialogForm data={data} conferenceName={conferenceName} />,
            {
                maxWidth: "xs",
            }
        );
    }

    const filteredData = searchText ? teamData && teamData.filter(v => v.name.toLowerCase().includes(searchText.toLowerCase())) : teamData && teamData;

    return (
        <div className={classes.container}>
            <BackArrow onClick={handleRedirect} />
            <div className={classes.search}>
                <Title title={conferenceName} />
                <div style={{display: "flex"}}>
                    <CreateTeam conferenceName={conferenceName}/>
                    <InputBase
                        placeholder="Search Team Name"
                        value={searchText}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{'aria-label': 'search'}}
                        onChange={ev => handleSearchText(ev.target.value)}
                    />
                </div>
            </div>
            <Grid container spacing={1}>
                {
                    loading ?
                        <ListLoading/>
                        :
                        filteredData && filteredData.length === 0 ?
                            <Typography style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '10px',
                                color: '#e61e21'
                            }}>
                                No team found
                            </Typography>
                            :
                            filteredData && filteredData.map(list => (
                                <Grid item xs={12} sm={4}>
                                    <Card className={classes.card} onClick={() => handleEditClick(list)}>
                                            <span className={classes.fullName}>
                                                {list.name}
                                            </span>
                                    </Card>
                                </Grid>
                            ))
                }
            </Grid>
        </div>
    )
}

let mapStateToProps = (state) => {
    return {
        loadingDeleteConference: state.ui.conference.deleteConferenceReducer,
        teamData: state.conference.teamByConference,
        loading: state.ui.conference.loadingTeamByConference,
        conferenceName: state.ui.conference.selectedConferenceName,
    };
};

export default connect(mapStateToProps)(ConferenceDetails);
export const GET_ALL_SPORTS_REQUEST = "GET_ALL_SPORTS_REQUEST";
export const GET_ALL_SPORTS_SUCCESS = "GET_ALL_SPORTS_SUCCESS";
export const GET_ALL_SPORTS_FAILED = "GET_ALL_SPORTS_FAILED";

export const GET_SPORT_BY_ID_REQUEST = "GET_SPORT_BY_ID_REQUEST";
export const GET_SPORT_BY_ID_SUCCESS = "GET_SPORT_BY_ID_SUCCESS";
export const GET_SPORT_BY_ID_FAILED = "GET_SPORT_BY_ID_FAILED";

export const SEARCH_SPORT = "SEARCH_SPORT";
export const RESET_SEARCH = "RESET_SEARCH";

export const SELECT_SPORT = "SELECT_SPORT";
export const DESELECT_SPORT = "DESELECT_SPORT";

export const RESET_SPORT_UNIT_BY_SPORT_ID = "RESET_SPORT_UNIT_BY_SPORT_ID"

export const SELECTED_PAGE_TYPE = "SELECTED_PAGE_TYPE"
export const SELECTED_SPORTS_TYPE = "SELECTED_SPORTS_TYPE"

export const GET_TEAM_USERS_REQUEST = "GET_TEAM_USERS_REQUEST";
export const GET_TEAM_USERS_SUCCESS = "GET_TEAM_USERS_SUCCESS";
export const GET_TEAM_USERS_FAILED = "GET_TEAM_USERS_FAILED";

export const CREATE_SPORT_REQUEST = "CREATE_SPORT_REQUEST";
export const CREATE_SPORT_SUCCESS = "CREATE_SPORT_SUCCESS";
export const CREATE_SPORT_FAILED = "CREATE_SPORT_FAILED";

export const UPDATE_SPORT_REQUEST = "UPDATE_SPORT_REQUEST";
export const UPDATE_SPORT_SUCCESS = "UPDATE_SPORT_SUCCESS";
export const UPDATE_SPORT_FAILED = "UPDATE_SPORT_FAILED";

export const RESET_SPORT_CREATION_ERROR = "RESET_SPORT_CREATION_ERROR";

export const SELECTED_SPORT_NAME = "SELECTED_SPORT_NAME";

// Units
export const GET_ALL_SPORTS_UNITS_REQUEST = "GET_ALL_SPORTS_UNITS_REQUEST";
export const GET_ALL_SPORTS_UNITS_SUCCESS = "GET_ALL_SPORTS_UNITS_SUCCESS";
export const GET_ALL_SPORTS_UNITS_FAILED = "GET_ALL_SPORTS_UNITS_FAILED";

export const GET_SPORT_UNIT_BY_SPORT_ID_REQUEST = 'GET_SPORT_UNIT_BY_SPORT_ID_REQUEST';
export const GET_SPORT_UNIT_BY_SPORT_ID_SUCCESS = 'GET_SPORT_UNIT_BY_SPORT_ID_SUCCESS';
export const GET_SPORT_UNIT_BY_SPORT_ID_FAILED = 'GET_SPORT_UNIT_BY_SPORT_ID_FAILED';

export const GET_UNIT_BY_ID_REQUEST = "GET_UNIT_BY_ID_REQUEST";
export const GET_UNIT_BY_ID_SUCCESS = "GET_UNIT_BY_ID_SUCCESS";
export const GET_UNIT_BY_ID_FAILED = "GET_UNIT_BY_ID_FAILED";

export const CREATE_UNIT_REQUEST = "CREATE_UNIT_REQUEST";
export const CREATE_UNIT_SUCCESS = "CREATE_UNIT_SUCCESS";
export const CREATE_UNIT_FAILED = "CREATE_UNIT_FAILED";

export const SELECT_SPORT_UNIT = "SELECT_SPORT_UNIT";
export const DESELECT_SPORT_UNIT = "DESELECT_SPORT_UNIT";

export const UPDATE_UNIT_BY_ID_REQUEST = "UPDATE_UNIT_BY_ID_REQUEST";
export const UPDATE_UNIT_BY_ID_SUCCESS = "UPDATE_UNIT_BY_ID_SUCCESS";
export const UPDATE_UNIT_BY_ID_FAILED = "UPDATE_UNIT_BY_ID_FAILED";

export const RESET_UNIT_CREATION_ERROR = "RESET_UNIT_CREATION_ERROR";

//Positions
export const GET_SPORT_POSITION_BY_UNIT_ID_REQUEST = "GET_SPORT_POSITION_BY_UNIT_ID_REQUEST";
export const GET_SPORT_POSITION_BY_UNIT_ID_SUCCESS = "GET_SPORT_POSITION_BY_UNIT_ID_SUCCESS";
export const GET_SPORT_POSITION_BY_UNIT_ID_FAILED = "GET_SPORT_POSITION_BY_UNIT_ID_FAILED";
export const RESET_SPORT_POSITION_BY_UNIT_ID = "RESET_SPORT_POSITION_BY_UNIT_ID";

export const GET_SPORT_POSITION_BY_ID_REQUEST = "GET_SPORT_POSITION_BY_ID_REQUEST";
export const GET_SPORT_POSITION_BY_ID_SUCCESS = "GET_SPORT_POSITION_BY_ID_SUCCESS";
export const GET_SPORT_POSITION_BY_ID_FAILED = "GET_SPORT_POSITION_BY_ID_FAILED";

export const DESELECT_SPORT_POSITION = 'DESELECT_SPORT_POSITION';

export const CREATE_POSITION_REQUEST = "CREATE_POSITION_REQUEST";
export const CREATE_POSITION_SUCCESS = "CREATE_POSITION_SUCCESS";
export const CREATE_POSITION_FAILED = "CREATE_POSITION_FAILED";

export const UPDATE_POSITION_REQUEST = "UPDATE_POSITION_REQUEST";
export const UPDATE_POSITION_SUCCESS = "UPDATE_POSITION_SUCCESS";
export const UPDATE_POSITION_FAILED = "UPDATE_POSITION_FAILED";

export const DELETE_POSITION_REQUEST = "DELETE_POSITION_REQUEST";
export const DELETE_POSITION_SUCCESS = "DELETE_POSITION_SUCCESS";
export const DELETE_POSITION_FAILED = "DELETE_POSITION_FAILED";

export const RESET_SPORT_POSITION_CREATION_ERROR = "RESET_SPORT_POSITION_CREATION_ERROR";

// Form type selection
export const FORM_NAME_SELECTED = "FORM_NAME_SELECTED";
export const FORM_NAME_DESELECTED = "FORM_NAME_DESELECTED";

export const FORM_STATE_SELECTED = "FORM_STATE_SELECTED";
export const FORM_STATE_DESELECTED = "FORM_STATE_DESELECTED";

import { combineReducers } from "redux";
import * as types from "./conferenceType";

const getAllConferenceData = (state = [], action) => {
  const { payload } = action;
  if (action.type === types.SET_CONFERENCES_SUCCESS) return payload
  return state;
};

const teamByConference = (state = [], action) => {
  const { payload } = action;
   if(action.type === types.GET_TEAMS_BY_CONFERENCE_ID_SUCCESS) return payload.teams;
   if (action.type === types.RESET_TEAMS_BY_CONFERENCE_ID) return payload;
   return state;
};

const sportByConference = (state = [], action) => {
  const { payload } = action;
  if (action.type === types.GET_SPORTS_BY_CONFERENCE_ID_SUCCESS) return payload
  return state;
};

const allCountries = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_COUNTRIES_SUCCESS: {
      return { ...payload.country };
    }
    default:
      return state
  }
};

const allStateByCountry = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_COUNTRIES_STATES_SUCCESS: {
      return { ...payload.state };
    }
    default: {
      return state
    }
  }
};

const allConferenceByType = (state = [], action) => {
  const { payload } = action;
  if (action.type === types.GET_CONFERENCE_BY_TYPE_SUCCESS) return payload
  return state;
};

const newTeamId = (state = "", action) => {
  const { payload } = action;
  switch (action.type) {

    case types.CREATE_NEW_TEAM_SUCCESS: {
      return payload.id
    }

    case types.RESET_CREATE_NEW_TEAM: {
      return ""
    }

    default:
      return state;
  }
};

const selectedConference = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_CONFERENCE_BY_ID_SUCCESS: {
      return payload;
    }

    case types.DESELECT_CONFERENCE: {
      return payload;
    }

    default:
      return state;
  }
};

export default combineReducers({
  allConferenceList: getAllConferenceData,
  teamByConference,
  allCountries,
  allStateByCountry,
  allConferenceByType,
  sportByConference,
  newTeamId,
  selectedConference,
});
